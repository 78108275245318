<template>
  <div>
    <div class="twoColumn">
      <template v-for="(line, index) in localValue.linkageGoodsLineCo">
        <template v-for="group in linkageGoodsLineCo">
          <tw-process-group-list-edit-goods-line-item
            v-for="item in group.children"
            v-show="index === selectGoodsLineIndex"
            :key="item.key + '_' + index"
            :item="item"
            :selectGoodsIndex="index"
            :tableName="'linkageGoodsLineCo'"
            :groupName="group.variableName"
            :parentGroup="group"
            v-model="localValue"
            :serialItems="serialsParent.linkageGoodsLineCo[index][group.variableName].serialItems"
            :serialIndexes="serialsParent.linkageGoodsLineCo[index][group.variableName].serialIndexes"
            :index="index"
            @add-index="addIndex"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import TwProcessGroupListEditGoodsLineItem from '@/components/molecules/TwProcessGroupListEditGoodsLineItem';
import { serialGrpName, getSerialGrpItems, getSerialGrpIndex, getKey } from '@/utils/processedSerialItems';

export default {
  name: 'TwProcessGroupListEditGoodsLineCo',
  props: {
    value: Object,
    selectGoodsLineIndex: Number,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditGoodsLineItem,
  },
  data() {
    return {
      init: false,
      items: [],
      override: false,
      serialsParent: {}, // 連番項目グループ
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    multipleSelect() {
      return (model) => {
        return _.isArray(model) && model.length > 1;
      };
    },
    linkageGoodsLineCo() {
      const linkageGoodsLineCo = _.find(this.s.schemas.tables, {variableName: 'linkageGoodsLineCo'});
      let ret = [
        _.find(linkageGoodsLineCo.groups, {variableName: 'remarksGrp'}),
      ];
      return ret;
    },
  },
  watch: {
    // goods追加時、連番項目を初期化
    'localValue.linkageGoodsLineCo': {
      handler() {
        this.initSerial('linkageGoodsLineCo');
      }
    },
  },
  created() {
    // 連番項目init処理
    this.initSerial('linkageGoodsLineCo');
  },
  methods: {
    // remarks等の連番項目をグループ化し可変にします
    initSerial(t_name) {
      this.serialsParent[t_name] = [];
      _.forEach(this.localValue[t_name], (items, parent_key) => {
        this.serialsParent[t_name][parent_key] = {};
        _.forEach(items, (item, key) => {
          const res = this.localValue[t_name][parent_key][key];
          const serialItems = serialGrpName.includes(key) ? getSerialGrpItems(res, key) : {};
          let serialIndexes = {};
          if (Object.keys(serialItems).length > 0) serialIndexes = getSerialGrpIndex(serialItems);
          this.serialsParent[t_name][parent_key][key] = { serialItems: serialItems, serialIndexes: serialIndexes };
        });
      });
    },
    // 連番項目を追加 or 削除します
    addIndex(t_name, index, g_name, name, num) {
      const key = getKey(name);
      this.serialsParent[t_name][index][g_name].serialIndexes[key] += num;
      this.serialsParent = this.s.cloneDeep(this.serialsParent);
    },
  }
};
</script>

<style lang="scss" scoped>
  .twoColumn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-left: 16px;
  }

  .table_name {
    width: 100%;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 16px;
    line-height: 24px;
    color: $color_gray_800;
    white-space: nowrap;
    border-top: 1px solid $color_gray_300;
    margin-bottom: 32px;
  }
</style>
